.top-container {
  display: flex;
}

.search-box {
  width: 370px;
}

@media screen and (max-width: 1200px) {
  .top-container {
    flex-direction: column;
  }
  .main-child {
    margin-bottom: 20px;
  }
  .child {
    width: 100vw;
  }
}
