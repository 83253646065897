.fp-container{
    position: fixed;
    display: flex;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: #f8f8f8ad;
}

.fp-container .fp-loader{
    display: flex;
    justify-content: center;
    align-items: center !important;
    z-index: 1000;
    top: 40%;
    left: 35%;
    position: absolute;
}
.fp-container .fp-loaders{
    display: flex;
    justify-content: center;
    align-items: center !important;
    z-index: 1000;
    top: 40%;
    position: absolute;
}