.head h2 {
    font-size: 30px;
    color: var(--grey);
    margin: 0;
}
.head p {
    font-size: 16px;
    color: var(--light-grey);
    margin: 0;
}
.search-input {
    color: var(--purple);
    /*background-color: var(--purple);*/
    border: 1px solid var(--purple);
    /*outline: none;*/
    width: 100%;
    min-height: 40px;
    border-radius: 10px;
    padding: 10px;
}
.search-input::placeholder {
    color: var(--purple);
}
.filter-btn-container button {
    background-color: var(--white);
    border: none;
    outline: none;
    min-width: 150px;
    border-radius: 10px;
    color: var(--grey);
}
.filter-btn-container button.active {
    background-color: var(--purple);
    color: var(--white);
}
.filter-btn-container button:hover {
    background-color: var(--purple);
    color: var(--white);
}
.job-list {
    background-color: var(--white);
    border-radius: 8px;
    border: 1px solid var(--light-grey);
    min-height: 100px;
}
.avatar {
    width: 50px;
    height: 50px;
    object-fit: contain;
    border-radius: 40px;
    margin: 10px 0;
}
.job-list span {
    margin-left: 20px;
}
.job-list span h2 {
    font-size: 20px;
    color: var(--grey);
    margin: 0;
}
.job-list span h2.status {
    color: var(--purple);
}
.job-list span p {
    font-size: 14px;
    color: var(--grey);
    margin: 0;
}
.job-list .fa-industry {
    background-color: var(--light-purple);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: var(--purple);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}
.job-list a {
    background-color: var(--light-purple);
    border: none;
    outline: none;
    min-width: 100px;
    border-radius: 20px;
    color: var(--purple);
}
.job-list a:hover {
    background-color: var(--light-purple);
    color: var(--purple);
}
.pagination {
    background-color: var(--white);
    border-radius: 8px;
    border: 1px solid var(--light-grey);
}
.pagination .btn.active {
    border: 1px solid var(--grey);
}

.row-animation {
    width: 100%;
    justify-content: center;
    margin-right: 0px !important;
    animation-name: rowAnimationStartUp;
    animation-duration: 2s;
    /*animation-delay: 2s;*/
}

@keyframes rowAnimationStartUp {
    0% {
        width: 80%;
    }
    100% {
        width: 100%;
    }
}

.custom-card {
    background-color: var(--white);
    color: var(--purple);
    border-radius: 15px !important;
    border: none;
    transition: 0.5s;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    /*box-shadow: rgba(50, 50, 93, 0.35) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;*/
}

.custom-card:hover {
    background-color: var(--white);
    color: var(--purple);
    border: none;
    border-radius: 15px !important;
    transform: translateY(-5px);
    box-shadow: rgba(0, 0, 0, 0.30) 0px 10px 20px, rgba(0, 0, 0, 0.35) 0px 10px 10px;
    /*box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;*/
}

.custom-card p {
    font-size: 1.2vw;
    font-weight: bolder;
    color: #FCA120 !important;
}

.purple-card-heading {
    color: var(--purple);
}

.dashboard-purple-card-icon {
    margin-top: -50px;
    padding: 20px 30px 20px 30px !important;
    border-radius: 20px;
    border: none;
    background-color: var(--purple);
    box-shadow: rgba(0, 0, 0, 0.30) 0px 10px 20px, rgba(0, 0, 0, 0.35) 0px 10px 10px;
    font-size: 1.5vw;
    /*color: #b38dff !important;*/
    color: #ffffff !important;
    transition: 2s;
}

.dashboard-purple-card-icon:hover {
    padding: 20px 30px 20px 30px !important;
    border-radius: 30px;
    border: none;
    background-color: var(--purple);
    font-size: 1.8vw;
    /*color: #b38dff !important;*/
    color: #ffffff !important;
    transform: translateY(20px);
}

.green-card-heading {
    color: var(--green);
    /*color: #6DDD5D;*/
}

.dashboard-green-card-icon {
    margin-top: -50px;
    padding: 20px 30px 20px 30px !important;
    border-radius: 20px;
    border: none;
    background-color: var(--green);
    /*background-color: #6DDD5D;*/
    box-shadow: rgba(0, 0, 0, 0.30) 0px 10px 20px, rgba(0, 0, 0, 0.35) 0px 10px 10px;
    font-size: 1.5vw;
    /*color: #b38dff !important;*/
    color: #ffffff !important;
    transition: 2s;
}

.dashboard-green-card-icon:hover {
    padding: 20px 30px 20px 30px !important;
    border-radius: 30px;
    border: none;
    /*background-color: #6DDD5D;*/
    background-color: var(--green);
    font-size: 1.8vw;
    /*color: #b38dff !important;*/
    color: #ffffff !important;
    transform: translateY(20px);
}

.yellow-card-heading {
    color: #DDC45D;
}

.dashboard-yellow-card-icon {
    margin-top: -50px;
    padding: 20px 30px 20px 30px !important;
    border-radius: 20px;
    border: none;
    background-color: #DDC45D;
    box-shadow: rgba(0, 0, 0, 0.30) 0px 10px 20px, rgba(0, 0, 0, 0.35) 0px 10px 10px;
    font-size: 1.5vw;
    /*color: #b38dff !important;*/
    color: #ffffff !important;
    transition: 2s;
}

.dashboard-yellow-card-icon:hover {
    padding: 20px 30px 20px 30px !important;
    border-radius: 30px;
    border: none;
    background-color: #DDC45D;
    font-size: 1.8vw;
    /*color: #b38dff !important;*/
    color: #ffffff !important;
    transform: translateY(20px);
}

/*Cards With Charts CSS*/
.dxc-val-elements text{
    fill: #ffffff !important;
}

.dxc-arg-elements text{
    fill: #ffffff !important;
}

.dashboard-purple-card-chart {
    /*margin-top: -150px;*/
    padding: 20px 30px 20px 30px !important;
    border-radius: 20px;
    border: none;
    background-color: var(--purple);
    box-shadow: rgba(0, 0, 0, 0.30) 0px 10px 20px, rgba(0, 0, 0, 0.35) 0px 10px 10px;
    font-size: 2vw;
    /*color: #b38dff !important;*/
    color: #ffffff !important;
    transition: 2s;
}

.dashboard-purple-card-chart:hover {
    padding: 20px 30px 20px 30px !important;
    border-radius: 30px;
    border: none;
    background-color: var(--purple);
    font-size: 2.2vw;
    /*color: #b38dff !important;*/
    color: #ffffff !important;
    /*margin-top: -160px;*/
}

.dashboard-green-card-chart {
    margin-top: -150px;
    padding: 20px 30px 20px 30px !important;
    border-radius: 20px;
    border: none;
    background-color: var(--green);
    box-shadow: rgba(0, 0, 0, 0.30) 0px 10px 20px, rgba(0, 0, 0, 0.35) 0px 10px 10px;
    font-size: 2vw;
    /*color: #b38dff !important;*/
    color: #ffffff !important;
    transition: 2s;
}

.dashboard-green-card-chart:hover {
    padding: 20px 30px 20px 30px !important;
    border-radius: 30px;
    border: none;
    background-color: var(--green);
    font-size: 2.2vw;
    /*color: #b38dff !important;*/
    color: #ffffff !important;
    margin-top: -160px;
}

.dashboard-yellow-card-chart {
    margin-top: -150px;
    padding: 20px 30px 20px 30px !important;
    border-radius: 20px;
    border: none;
    background-color: #DDC45D;
    box-shadow: rgba(0, 0, 0, 0.30) 0px 10px 20px, rgba(0, 0, 0, 0.35) 0px 10px 10px;
    font-size: 2vw;
    /*color: #b38dff !important;*/
    color: #ffffff !important;
    transition: 2s;
}

.dashboard-yellow-card-chart:hover {
    padding: 20px 30px 20px 30px !important;
    border-radius: 30px;
    border: none;
    background-color: #DDC45D;
    font-size: 2.2vw;
    /*color: #b38dff !important;*/
    color: #ffffff !important;
    margin-top: -160px;
}
