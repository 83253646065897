.head h2 {
  font-size: 30px;
  color: var(--grey);
  margin: 0;
}
.head p {
  font-size: 16px;
  color: var(--light-grey);
  margin: 0;
}
.search-input {
  color: var(--purple);
  /*background-color: var(--light-purple);*/
  border: 1px solid var(--purple);
  /*outline: none;*/
  width: 100%;
  min-height: 40px;
  border-radius: 10px;
  padding: 10px;
}
.search-input::placeholder {
  color: var(--purple);
}
.MuiCardHeader-action .filter-btn-container button {
  background-color: var(--purple);
  border: none;
  outline: none;
  min-width: 100px;
  border-radius: 10px;
  color: var(--white);
}
.MuiCardHeader-action .filter-btn-container button.active {
  color: var(--purple);
  background-color: var(--white);
}
.MuiCardHeader-action .filter-btn-container button:hover {
  color: var(--purple);
  background-color: var(--white);
}
.job-list {
  background-color: var(--white);
  border-radius: 8px;
  border: 1px solid var(--light-grey);
  min-height: 100px;
}
.avatar {
  width: 50px;
  height: 50px;
  object-fit: contain;
  border-radius: 40px;
  margin: 10px 0;
}
.job-list span {
  margin-left: 20px;
}
.job-list span h2 {
  font-size: 20px;
  color: var(--grey);
  margin: 0;
}
.job-list span h2.status {
  color: var(--purple);
}
.job-list span p {
  font-size: 14px;
  color: var(--grey);
  margin: 0;
}
.job-list .fa-industry {
  background-color: var(--light-purple);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: var(--purple);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}
.job-list a {
  background-color: var(--light-purple);
  border: none;
  outline: none;
  min-width: 100px;
  border-radius: 20px;
  color: var(--purple);
}
.job-list a:hover {
  background-color: var(--light-purple);
  color: var(--purple);
}
.pagination {
  background-color: var(--white);
  border-radius: 8px;
  border: 1px solid var(--light-grey);
}
.pagination .btn.active {
  border: 1px solid var(--grey);
}



/* NEW CSS */
.custom-card-header {
  background-color: var(--purple);
  color: var(--light-purple);
  /*padding: 0px 30px 30px 10px;*/
}


.recruiter-card-customization {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.recruiter-profile-card-customization {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}


.filter-btn-container {
  display: flex;
}

.main-styled-form-class .dx-field-value .dx-textbox {
  border-radius: 10px;
}

.main-styled-form-class .dx-field-value .dx-textbox .dx-texteditor-container {
  border: 1px solid var(--purple);
  border-radius: 10px;
}
.main-styled-form-class .dx-field-value .dx-checkbox-container {
  border: 1px solid var(--purple);
  border-radius: 10px;
}
